/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 86번째 로그에서는 네이버 멤버십, NHN 제2데이터센터 설립, 코모도 루트 인증서 만료, 판교거래소 거래 후기 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "네이버 멤버십"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nid.naver.com/membership/join"
  }, "네이버멤버십 : 네이버플러스 멤버십")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/amazonprime?_encoding=UTF8&%2AVersion%2A=1&%2Aentries%2A=0"
  }, "Amazon.com: Amazon Prime")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://flexibits.com/fantastical/pricing"
  }, "Fantastical Subscription")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://loyalty.coupang.com/loyalty/sign-up/home"
  }, "쿠팡!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://member.11st.co.kr/prime/intro"
  }, "멤버십 혜택 - 11번가 AllPRIME")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com/"
  }, "Netflix - Watch TV Shows Online, Watch Movies Online")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Subscription_business_model"
  }, "Subscription business model - Wikipedia")), "\n"), "\n", React.createElement(_components.h3, null, "NHN, 제 2데이터 센터"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2020/06/09878/"
  }, "NHN, 경남 김해에 두번째 데이터센터 짓는다 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20191226070100063"
  }, "세종에 세계 최대 규모 네이버 데이터센터…2022년 준공 | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datacenter.navercorp.com/"
  }, "DATA CENTER GAK")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/ko-kr/global-infrastructure/regions/"
  }, "Azure 지역 | Microsoft Azure")), "\n"), "\n", React.createElement(_components.h3, null, "Comodo, 루트 인증서 만료"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/comodo-ca-addtrust-external-root-certificates-expiration"
  }, "코모도 CA(Comodo)의 AddTrust External 루트 인증서 만료 | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sectigo.com/"
  }, "SSL Certificate Authority & PKI Solutions | Sectigo® Official")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://crt.sh/?id=1"
  }, "crt.sh | 1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.sectigo.com/articles/Knowledge/Sectigo-AddTrust-External-CA-Root-Expiring-May-30-2020"
  }, "Knowledge: Sectigo AddTrust External CA Root Expiring May 30, 2020")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://security.stackexchange.com/questions/232445/https-connection-to-specific-sites-fail-with-curl-on-macos"
  }, "tls - HTTPS connection to specific sites fail with cURL on macOS - Information Security Stack Exchange")), "\n"), "\n", React.createElement(_components.h3, null, "판교거래소"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://psx.or.kr/"
  }, "안전한 장외주식 PSX - 판교거래소")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wadiz.kr/web/winvest/main"
  }, "와디즈 투자 - 크라우드펀딩")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://psx.or.kr/info/trade/"
  }, "비상장주식 거래 절차 | 안전한 장외주식 PSX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.bizwatch.co.kr/article/market/2019/10/14/0025"
  }, "두나무·삼성증권, 비상장 플랫폼 런칭…차별점은?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://angelleague.io/about/"
  }, "엔젤리그, 1%를 향한 선택")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://psx.or.kr/company/yellomobile/"
  }, "옐로모바일 | 안전한 장외주식 PSX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://angelleague.io/angelleague/26"
  }, "엔젤리그 | 젠틀몬스터")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "layout: post\ntitle: \"스탠다드아웃_084.log: 네이버 통장 출시, 클럽하우스, 노션 퍼스널 플랜 무료, MS 빌드 2020\"\npermalink: /84/\ncontributors:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "name: 'raccoonyy'\nuri: 'https://twitter.com/raccoonyy'"), "\n", React.createElement(_components.li, null, "name: 'nacyot'\nuri: 'https://twitter.com/nacyot'"), "\n", React.createElement(_components.li, null, "name: seapy'\nuri: 'https://twitter.com/seapy'\nsocial:\ntwitter: \"스탠다드아웃 84번째 로그에서는 네이버 통장 출시, 클럽하우스, 노션 퍼스널 플랜 무료, MS 빌드 2020 등에 대해 이야기를 나눴습니다.\"\nfacebook: \"스탠다드아웃 84번째 로그에서는 네이버 통장 출시, 클럽하우스, 노션 퍼스널 플랜 무료, MS 빌드 2020 등에 대해 이야기를 나눴습니다.\"\nduration: \"00:51:02\"\nenclosure:\nurl: https://cdn-cf.podty.me/meta/episode_audio/513940/188843_1590651004659.mp3\nlength: 73508038"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "스탠다드아웃 84번째 로그에서는 네이버 통장 출시, 클럽하우스, 노션 퍼스널 플랜 무료, MS 빌드 2020 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "네이버 통장 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://campaign.naver.com/npay/naver-account/"
  }, "네이버통장")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.kakaobank.com/Notices/view/10783"
  }, "공지사항 | 카카오뱅크")), "\n"), "\n", React.createElement(_components.h3, null, "클럽하우스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forbes.com/sites/alexkonrad/2020/05/15/andreessen-horowitz-wins-vc-sweepstakes-to-back-clubhouse-voice-app/#5c60324c6f2a"
  }, "Andreessen Horowitz Wins VC Sweepstakes To Back Clubhouse, Voice App Still In Beta, At $100 Million Valuation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.axios.com/clubhouse-andreessen-horowitz-79107413-e83f-4203-8cfe-9f1e7dde0e2c.html"
  }, "Andreessen Horowitz leading deal for voice chat app Clubhouse - Axios")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.spooncast.net/kr/"
  }, "Spoon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://a16z.com/"
  }, "Homepage - Andreessen Horowitz")), "\n"), "\n", React.createElement(_components.h3, null, "노션 퍼스널 플랜 무료로 공개"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--notion-announced-personal-plan-for-free"
  }, "노션(Notion) 새로운 가격 정책 발표: 블럭 제한 없는 퍼스널 플랜을 무료로 제공 | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/pricing"
  }, "Notion – Pricing")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1261037710665322496"
  }, "Notion on Twitter: \"Performance is a feature! That's why we spent the last month optimizing mobile start up time. ...\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1260691517569560578"
  }, "Notion on Twitter: \"Faster mobile apps have been a top request for us for a long time now. ...")), "\n"), "\n", React.createElement(_components.h3, null, "마이크로소프트 개발 컨퍼런스 Build 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/terminal"
  }, "microsoft/terminal: The new Windows Terminal and the original Windows console host, all in the same place!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/ko-kr/services/quantum/"
  }, "Azure Quantum | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/wsl2-will-be-generally-available-in-windows-10-version-2004/"
  }, "WSL2 will be generally available in Windows 10, version 2004 | Windows Command Line")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/commandline/the-windows-subsystem-for-linux-build-2020-summary/"
  }, "The Windows Subsystem for Linux BUILD 2020 Summary | Windows Command Line")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/winget-cli"
  }, "microsoft/winget-cli: Windows Package Manager CLI (aka winget)")), "\n"), "\n", React.createElement(_components.h3, null, "분리발주제도 법안 통과"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/simnalamburt/status/1263416341689556993"
  }, "김지현 on Twitter: \"단계별 분리발주가 뭐냐면요. ..")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zdnet.co.kr/view/?no=20200520180528"
  }, "SW진흥법 통과, 원격개발 등 업계 숙원 대부분 담겨 - ZDNet korea")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
